<template>
  <b-modal
    v-model="onControl"
    title="Edit Commission"
    title-tag="h3"
    modal-class="modal-primary"
    size="xl"
    no-close-on-backdrop
    @hidden="close"
  >
    <b-row>
      <b-col cols="12">
        <p
          class="
              rounded
              text-primary
              border-primary
              font-medium-1
              text-center
              py10
            "
        >
          {{
            commissionContentLocal.description
          }}
        </p>
      </b-col>
    </b-row>
    <validation-observer ref="form">
      <b-row>
        <b-col>
          <b-form-group
            label="Catcher"
          >
            <validation-provider
              v-slot="{errors}"
              name="amount_captured"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.amount_captured"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Seller Beginner (PE)"
          >
            <validation-provider
              v-slot="{errors}"
              name="seller_PE_bg"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.seller_PE_bg"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Seller Junior (PE)"
          >
            <validation-provider
              v-slot="{errors}"
              name="seller_PE_jr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.seller_PE_jr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Seller Junior (US)"
          >
            <validation-provider
              v-slot="{errors}"
              name="seller_US_jr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.seller_US_jr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Seller Senior (PE)"
          >
            <validation-provider
              v-slot="{errors}"
              name="seller_PE_sr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.seller_PE_sr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Seller Senior (US)"
          >
            <validation-provider
              v-slot="{errors}"
              name="seller_US_sr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.seller_US_sr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Seller Master (PE)"
          >
            <validation-provider
              v-slot="{errors}"
              name="seller_PE_mr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.seller_PE_mr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Catcher beginner"
          >
            <validation-provider
              v-slot="{errors}"
              name="catcher_bg"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.catcher_bg"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Catcher Junior"
          >
            <validation-provider
              v-slot="{errors}"
              name="catcher_jr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.catcher_jr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Catcher Senior"
          >
            <validation-provider
              v-slot="{errors}"
              name="catcher_sr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.catcher_sr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Recovery Beginner"
          >
            <validation-provider
              v-slot="{errors}"
              name="recovery_bg"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.recovery_bg"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Recovery Senior"
          >
            <validation-provider
              v-slot="{errors}"
              name="recovery_sr"
              rules="required|validate-amount-zero"
            >
              <money
                v-model="commissionContentLocal.recovery_sr"
                v-bind="{
                  decimal: '.',
                  thousand: ',',
                  precision: 2,
                  min: 0.0,
                  prefix: '$ ',
                  maxlength: 10,
                }"
                class="form-control input-form"
              />
              <span
                v-if="errors[0]"
                class="text-danger"
              >{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group label="Commentary">
            <b-form-textarea v-model="commissionContentLocal.commentary"/>
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <template #modal-footer>
      <button-save @click="saveChangedCommission" />
    </template>
  </b-modal>
</template>

<script>
import ButtonSave from '@/views/commons/utilities/ButtonSave.vue'
import ManageCommissionsService from '@/views/crm/views/manage-commissions/manage-commissions.service'
import { mapGetters } from 'vuex'

export default {
  components: {
    ButtonSave,
  },
  props: {
    commissionContent: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  data() {
    return {
      onControl: false,
      commissionContentLocal: {
        status: 1,
        created_by: '',
        commentary: '',
        user_name: '',
        commission_description: '',
        program_name: '',
      },
    }
  },
  async created() {
    this.onControl = true
    this.commissionContentLocal.created_by = this.currentUser.user_id
    this.commissionContentLocal.user_name = this.currentUser.fullName
    this.commissionContentLocal = { ...this.commissionContentLocal, ...this.commissionContent }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async saveChangedCommission() {
      try {
        const result = await this.$refs.form.validate()
        if (result) {
          const confirm = await this.showConfirmSwal()
          if (confirm.isConfirmed) {
            this.commissionContentLocal.commission_description = this.commissionContentLocal.description
            const data = await ManageCommissionsService.insertTrackingGeneralCommissions(this.commissionContentLocal)
            if (data.status === 200) {
              this.showSuccessSwal()
              this.$emit('refresh')
              this.close()
            }
          }
        }
      } catch (e) {
        console.log('ERRORS ON EditCommissionContent : saveChangedCommission ', e)
      }
    },
  },
}
</script>

<style scoped>

</style>
