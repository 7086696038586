import { amgApi } from '@/service/axios'

class ManageCommissionsService {
  async getProgramCommissions(body) {
    try {
      const data = await amgApi.post('/crm/manage-commissions/get-program-commissions', body)
      return data
    } catch (error) {
      console.log('ERRORS ON ManageCommissionsService: getCedAgentsCommissions ', error)
    }
  }
  async getRolesCommissionsByProgram(body) {
    try {
      const data = await amgApi.post('/crm/manage-commissions/get-roles-commissions-by-program', body)
      return data
    } catch (error) {
      console.log('ERRORS ON ManageCommissionsService: getCedAgentsCommissions ', error)
    }
  }
  async insertTrackingGeneralCommissions(body) {
    try {
      const data = await amgApi.post('/crm/manage-commissions/insert-tracking-general-commissions', body)
      return data
    } catch (error) {
      console.log('ERRORS ON ManageCommissionsService: getCedAgentsCommissions ', error)
    }
  }
}

export default new ManageCommissionsService()
