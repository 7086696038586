<template>
  <div>
    <b-modal
      v-model="onControl"
      title="Commission Tracking"
      title-tag="h3"
      modal-class="modal-primary"
      size="xl"
      hide-footer
      @hidden="close"
    >
      <filter-slot
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        :filter="filters"
        :filter-principal="filterPrincipal"
        @reload="$refs['refTrackingTable'].refresh()"
      >
        <template #table>
          <b-table
            ref="refTrackingTable"
            no-border-collapse
            class="position-relative"
            :fields="fields"
            show-empty
            no-provider-filtering
            sticky-header="60vh"
            primary-key="id"
            responsive="sm"
            :items="searchSalesProcessCommissions"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(created_at)="data">
              <span>{{ data.item.created_by_name }}</span>
              <br>
              <span>{{ data.item.created_at | myGlobalWithHour }} </span>
            </template>
            <template #cell(value)="data">
              <b-badge
                :variant="getVariantCategory(data.item.program_id)"
                class="text-center w-100"
              >
                {{ data.item.value }}
              </b-badge>
            </template>
            <template #cell(status)="data">
              <b-badge
                :variant="getVariantColor(data.item.status_num)"
                class="text-center w-100"
              >
                {{ data.item.status }}
              </b-badge>
            </template>
            <template #cell(updated_at)="data">
              <span>{{ data.item.evaluated_by_name }}</span>
              <br>
              <span>{{ data.item.updated_at | myGlobalWithHour }} </span>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex">
                <b-button
                  class="btn-icon"
                  variant="primary"
                  @click="openApproveCommissionProcessSale(data.item.id, data.item.commissions_id)"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="15"
                  />
                </b-button>
                <b-button
                  v-if="data.item.status_num === 1"
                  variant="outline-danger"
                  class="ml-1"
                  @click="cancelCommission(data.item)"
                >
                  <feather-icon
                    icon="XIcon"
                  /> Cancel
                </b-button>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
      <modal-approve-commission-process-sale
        v-if="approveCommissionProcessSaleOn"
        :edited-commission-id="editedCommissionId"
        :commission-id="commissionId"
        :view-from-crm-tracking="true"
        @refresh="$refs['refTrackingTable'].refresh()"
        @close="closeApproveCommissionProcessSale"
      />
    </b-modal>
  </div>
</template>
<script>
import ModalApproveCommissionProcessSale from '@/views/commons/components/commissions/modals/ModalApproveCommissionProcessSale.vue'
import SalesProcessCommissions from '@/views/commons/components/commissions/tables/data/sales-process-commissions'
import CeSetCommissionsService from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import { mapGetters } from 'vuex'

export default {
  components: {
    ModalApproveCommissionProcessSale,
  },
  props: {
    trackedCommissionId: { // commission that is tracked, of the table commissions
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tab: 0,
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      fields: [
        {
          key: 'description',
          sortable: false,
          label: 'Commission',
          visible: true,
        },
        {
          key: 'commentary',
          sortable: false,
          label: 'Commentary',
          visible: true,
        },
        {
          key: 'value',
          sortable: false,
          label: 'Program',
          visible: true,
        },
        {
          key: 'created_at',
          sortable: true,
          label: 'Requested by',
          visible: true,
        },
        {
          key: 'updated_at',
          sortable: true,
          label: 'Evaluated by',
          visible: true,
        },
        {
          key: 'status',
          sortable: false,
          label: 'Status',
          visible: true,
        },
        {
          key: 'actions',
          sortable: false,
          label: 'Actions',
          visible: true,
        },
      ],
      filters: SalesProcessCommissions,
      trackingItems: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortBy: 'created_at',
      sortDesc: true,
      searchInput: '',
      isBusy: false,
      commissionTypes: [],
      approveCommissionProcessSaleOn: false,
      editedCommissionId: 0,
      commissionId: 0,
    }
  },
  async created() {
    this.onControl = true
    this.filters[3].visible = false
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async searchSalesProcessCommissions(ctx) {
      const response = await CeSetCommissionsService.searchSalesProcessCommissions({
        dateFrom: this.filters[0].model,
        dateTo: this.filters[1].model,
        status: this.filters[2].model,
        programId: this.filters[3].model,
        trackingId: this.trackedCommissionId,
        inProcess: this.status,
        page: ctx.currentPage,
        order: ctx.sortDesc ? 'desc' : 'asc',
        orderBy: ctx.sortBy,
        perPage: ctx.perPage,
        text: this.filterPrincipal.model,
      })
      this.trackingItems = response.data.data
      this.startPage = response.data.from
      this.paginate.currentPage = response.data.current_page
      this.paginate.perPage = response.data.per_page
      this.totalRows = response.data.total
      this.toPage = response.data.to
      return this.trackingItems || []
    },
    getVariantColor(status) {
      switch (status) {
        case 1:
          return 'light-primary'
        case 2:
          return 'light-success'
        case 3:
          return 'light-danger'
        default:
          return 'light-info'
      }
    },
    getVariantCategory(status) {
      switch (status) {
        case 1:
          return 'light-primary'
        case 2:
          return 'light-warning'
        case 3:
          return 'light-success'
        default:
          return 'light-info'
      }
    },
    openApproveCommissionProcessSale(id, commissionId) {
      this.approveCommissionProcessSaleOn = true
      this.editedCommissionId = id
      this.commissionId = commissionId
    },
    closeApproveCommissionProcessSale() {
      this.approveCommissionProcessSaleOn = false
    },
    async cancelCommission(item) {
      const response = await this.showConfirmSwal()
      if (response.isConfirmed) {
        const data = await CeSetCommissionsService.approveDisapproveSalesCommission({
          status: 4, // 4 is cancel
          commission_tracking_id: item.id,
          commission_id: this.trackedCommissionId,
          user_id: this.currentUser.user_id,
        })
        if (data.status === 200) {
          this.showSuccessSwal()
          this.$refs.refTrackingTable.refresh()
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
