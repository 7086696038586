<template>
  <div class="border-top-info border-3 border-table-radius px-0">
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refCommissionsTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refCommissionsTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="getProgramCommissions"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template
            #cell(seller_PE_bg)="data"
          >
            $ {{ data.item.seller_PE_bg | currency }}
          </template>
          <template
            #cell(seller_PE_jr)="data"
          >
            <div>
              $ {{ data.item.seller_PE_jr | currency }} | $ {{ data.item.seller_US_jr | currency }}
            </div>
          </template>
          <template
            #cell(seller_PE_sr)="data"
          >
            $ {{ data.item.seller_PE_sr | currency }} | $ {{ data.item.seller_US_sr | currency }}
          </template>
          <template
            #cell(seller_PE_mr)="data"
          >
            $ {{ data.item.seller_PE_mr | currency }}
          </template>
          <template
            #cell(amount_captured)="data"
          >
            $ {{ data.item.amount_captured | currency }}
          </template>
          <template
            #cell(catcher_bg)="data"
          >
            $ {{ data.item.catcher_bg | currency }}
          </template>
          <template
            #cell(catcher_jr)="data"
          >
            $ {{ data.item.catcher_jr | currency }}
          </template>
          <template
            #cell(catcher_sr)="data"
          >
            $ {{ data.item.catcher_sr | currency }}
          </template>
          <template
            #cell(recovery_bg)="data"
          >
            $ {{ data.item.recovery_bg | currency }}
          </template>
          <template
            #cell(recovery_sr)="data"
          >
            $ {{ data.item.recovery_sr | currency }}
          </template>
          <template
            #cell(actions)="data"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                v-if="data.item.cc_pending >= 1"
                v-b-tooltip.hover.top="'Request change is pending'"
                icon="InfoIcon"
                size="15"
                class="text-danger cursor-pointer"
              />
              <feather-icon
                v-else
                icon="Edit2Icon"
                size="15"
                class="text-warning cursor-pointer"
                @click="openEditCommissionContentModal(data.item)"
              />
              <feather-icon
                icon="ListIcon"
                size="15"
                class="text-primary ml-1 cursor-pointer"
                @click="openTypesTrackingModal(data.item)"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <edit-commission-content
      v-if="editCommissionContentOn"
      :commission-content="commissionToEdit"
      @refresh="$refs['refCommissionsTable'].refresh()"
      @close="closeEditCommissionContentModal"
    />
    <types-tracking-modal
      v-if="typesTrackingModalOn"
      :tracked-commission-id="trackedCommissionId"
      @refresh="$refs['refCommissionsTable'].refresh()"
      @close="closeTypesTrackingModal"
    />
  </div>
</template>

<script>
import ManageCommissionsService from '@/views/crm/views/manage-commissions/manage-commissions.service'
import EditCommissionContent from '@/views/crm/views/manage-commissions/components/modals/EditCommissionContent.vue'
import TypesTrackingModal from '@/views/crm/views/manage-commissions/components/modals/TypesTrackingModal.vue'

export default {
  name: 'ContentCommissions',
  components: {
    EditCommissionContent,
    TypesTrackingModal,
  },
  data() {
    return {
      fields: [
        {
          key: 'description',
          sortable: false,
          label: 'Description',
          visible: true,
        },
        {
          key: 'amount_captured',
          sortable: false,
          label: 'Amount for catcher',
          visible: true,
        },
        {
          key: 'seller_PE_bg',
          sortable: false,
          label: 'Seller beginner (PE)',
          visible: true,
        },
        {
          key: 'seller_PE_jr',
          sortable: false,
          label: 'Seller Junior (PE) | (US)',
          visible: true,
          thStyle: {
            width: '170px',
          },
        },
        {
          key: 'seller_PE_sr',
          sortable: false,
          label: 'Seller Senior (PE) | (US)',
          visible: true,
          thStyle: {
            width: '170px',
          },
        },
        {
          key: 'seller_PE_mr',
          sortable: false,
          label: 'Seller Master (PE)',
          visible: true,
        },
        {
          key: 'catcher_bg',
          sortable: false,
          label: 'Catcher beginner',
          visible: true,
        },
        {
          key: 'catcher_jr',
          sortable: false,
          label: 'Catcher junior',
          visible: true,
        },
        {
          key: 'catcher_sr',
          sortable: false,
          label: 'Catcher senior',
          visible: true,
        },
        {
          key: 'recovery_bg',
          sortable: false,
          label: 'Recovery beginner',
          visible: true,
        },
        {
          key: 'recovery_sr',
          sortable: false,
          label: 'Recovery senior',
          visible: true,
        },
        {
          key: 'actions',
          sortable: false,
          label: 'Actions',
          visible: true,
        },
      ],
      itemsCommissions: [],
      editCommissionContentOn: false,
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'created_at',
      sortDesc: true,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      commissionToEdit: {},
      typesTrackingModalOn: false,
      trackedCommissionId: null,
    }
  },
  methods: {
    async getProgramCommissions(ctx) {
      const data = await ManageCommissionsService.getProgramCommissions({
        text: this.filterPrincipal.model,
        programId: this.$route.meta.programId,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        orderBy: ctx.sortBy ? ctx.sortBy : 'created_at',
        order: ctx.sortDesc ? 'desc' : 'asc',
      })
      this.itemsCommissions = data.data.data
      this.startPage = data.data.from
      this.paginate.currentPage = data.data.current_page
      this.paginate.perPage = data.data.per_page
      this.totalRows = data.data.total
      this.toPage = data.data.to
      return this.itemsCommissions || []
    },
    openEditCommissionContentModal(item) {
      this.editCommissionContentOn = true
      this.commissionToEdit = item
    },
    closeEditCommissionContentModal() {
      this.editCommissionContentOn = false
    },
    openTypesTrackingModal(item) {
      this.typesTrackingModalOn = true
      this.trackedCommissionId = item.id
    },
    closeTypesTrackingModal() {
      this.typesTrackingModalOn = false
    },
  },
}
</script>

<style scoped>

</style>
